import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from 'gatsby-background-image';
import loadable from '@loadable/component'
// Layout
import MainLayout from "../components/layouts/MainLayout";
import MainBanner from '../components/templates/MainBanner';
import CategoriesCard from '../components/templates/CategoriesCard';
// Components
import Seo from "../components/templates/Seo/seo";
// Styled
import styled from 'styled-components';
// Utils
import { tablet } from "../assets/styles/Responsive";

//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const Reviews = loadable(() => import('../components/templates/Reviews'));
const Links = loadable(() => import('../components/templates/Links'));
const BlogWidget = loadable(() => import('../components/templates/BlogWidget'));
const Brands = loadable(() => import('../components/templates/Brands'));
const Education = loadable(() => import('../components/templates/Education'));
const Location = loadable(() => import('../components/templates/Location'));

const Wrapper = styled.div`
  position: relative;
  padding: 75px 0 35px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: linear-gradient(360deg, #CBDFD6 1.75%, #77948C 100%);
    transform: rotate(180deg);
  }
`;

const ReviewsWrap = styled(BackgroundImage)`
  padding: 80px 0 110px 0px;
  background-size: cover;
  background-position: center;

  @media (max-width: ${tablet}) {
    padding: 0 0 70px 0;
  }
`;

const LinksContainer = styled.div`
  padding-top: 75px;

  @media (max-width: ${tablet}) {
    padding-top: 50px;
  }
`;

const IndexPage = () => {
  const {
    seo,
    banner,
    categories,
    reviews,
    reviewsBackground,
    links,
    brands,
    education,
    location,
  } = IndexQuery();

  const imageData = getImage(reviewsBackground?.localFile?.childImageSharp?.gatsbyImageData)
  const bgimage = convertToBgImage(imageData);

  const SEOSchema = {"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://torontocannabisauthority.ca/#website","url":"https://torontocannabisauthority.ca/","name":"TORONTO CANNABIS AUTHORITY","description":"We\u2019ve Got The Best Weed in Toronto!","potentialAction":[{"@type":"SearchAction","target":"https://torontocannabisauthority.ca/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://torontocannabisauthority.ca/#webpage","url":"https://torontocannabisauthority.ca/","name":"We\u2019ve Got The Best Weed in Toronto! - TORONTO CANNABIS AUTHORITY","isPartOf":{"@id":"https://torontocannabisauthority.ca/#website"},"datePublished":"2021-05-14T09:02:29+00:00","dateModified":"2021-05-14T10:28:42+00:00","breadcrumb":{"@id":"https://torontocannabisauthority.ca/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://torontocannabisauthority.ca/"]}]},{"@type":"BreadcrumbList","@id":"https://torontocannabisauthority.ca/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"item":{"@id":"https://torontocannabisauthority.ca/#webpage"}}]}]}
  return (
    <MainLayout noNavbar>
      {seo ? (
        <Seo
          title={seo.metaTitle}
          description={seo.metaDescription}
          keywords={seo.keywords}
          schemaMarkup={SEOSchema}
        />
      ) : null}
      <MainBanner data={banner} />
      <CategoriesCard data={categories} />
      <ReviewsWrap
        Tag="section" 
        {...bgimage}
      >
        <Reviews data={reviews} />
        <LinksContainer>
          <Links data={links} />
        </LinksContainer>
      </ReviewsWrap>
      <Wrapper>
        <BlogWidget />
        {/*<Brands data={brands} />*/}
      </Wrapper>
      <Education data={education} />
      <Location data={location} />
    </MainLayout>
  );
};

export default IndexPage;

const IndexQuery = () => {
  const data = useStaticQuery(
    graphql`
      query IndexQuery {
        strapiContent {
          seo {
            metaDescription
            metaTitle
            keywords
          }
          banner {
            button {
              text
              href
            }
            subtitle
            title
            text
            background {
              localFile {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
            }
            backBackground {
              localFile {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
            }
          }
          categories {
            subtitle
            title
            category {
              id
              name
              image {
                name
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
              link
            }
          }
          reviews {
            title
            Carousel {
              id
              text
              name
              address
              rating
            }
          }
          reviewsBackground {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          links {
            id
            link
            subtitle
            title
          }
          brands {
            title
            brands {
              id
              image {
                name
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
          }
          education {
            education {
              button
              description
              id
              title
              image {
                name
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
            background {
              localFile {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
            }
          }
          location {
            title
            description
            button
            map {
              name
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            background {
              localFile {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
      }
    `
  );

  const {
    strapiContent,
  } = data;

  return strapiContent;
};
