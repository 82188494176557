import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
// Fonts
import Fonts from '../../../assets/styles/Fonts';
import Colors from '../../../assets/styles/Colors';
// Assets
import ArrowRight from '../../../images/icons/arrow-right.svg';
import { tablet } from "../../../assets/styles/Responsive";

const Element = css`
  padding: ${props => props.sm ? '16px 15px' : '27px 15px'};
  min-width: ${props => props.sm ? '250px' : '340px'};
  display: inline-block;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: ${props => props.sm ? '18px' : '24px'};
  line-height: ${props => props.sm ? '18px' : '31.2px'};
  font-family: ${Fonts.IBMSemiBold};
  background-color: ${Colors.green};
  transition: background-color .1s linear;

  svg {
    transition: transform .1s linear;
  }

  &:hover {
    background-color: ${Colors.mineralGreen};
    svg {
      transform: translate(5px, 0);
    }
  }

  @media (max-width: ${tablet}) {
    min-width: 250px;
    padding: ${props => props.sm ? '16px 15px' : '23px 15px'};
    font-size: 18px;
    line-height: 23px;
  }
`;

const Btn = styled.button`
  ${Element};
`;

const LinkedBtn = styled(Link)`
  ${Element};
`;

const Button = ({
  value, className, sm = false, href = '',
}) => {
  // console.log("BUTTON "+value+" href--> " + href)
  return(
  href ? (
    <LinkedBtn className={className} sm={sm} to={href}>
      {value} <ArrowRight />
    </LinkedBtn>
  ) : (
    <Btn className={className} sm={sm} type="button">
      {value} <ArrowRight />
    </Btn>
  ))
};

Button.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Button;
