import { Link } from 'gatsby';
// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
import Fonts from '../../../assets/styles/Fonts';
import Typography from '../../../assets/styles/Typography';
import { tablet } from "../../../assets/styles/Responsive";
// UI
import H1 from '../../UI/Titles/H1';
import H4 from '../../UI/Titles/H4';
import Carousel from '../../UI/Carousel';

const Wrapper = styled.div`
  overflow: hidden;
  padding-bottom: ${props => props.bottomSubtitle ? '55px' : '10px'};
  background-color: ${Colors.mineralGreen};

  @media (max-width: ${tablet}) {
    padding-bottom: 80px;
  }
`;

const Head1 = styled(H1)`
  padding: 105px 0 ${props => props.bottomSubtitle ? '105px' : '60px'};
  text-align: center;
  color: ${Colors.greenLight};
 
  @media (max-width: ${tablet}) {
    padding: 50px 0 ${props => props.bottomSubtitle ? '80px' : '40px'};
    line-height: 30px;
  }
`;

const Subhead = styled.p`
  max-width: 600px;
  margin: 0 auto 90px;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  color: ${Colors.greenLight};
  font-family: ${Fonts.IBMRegular};

  @media (max-width: ${tablet}) {
    margin: 0 auto 40px;
    font-size: 12px;
    line-height: 13.2px;
  }
`;

const SliderSlick = styled(Carousel)`
  @media (max-width: ${tablet}) {
    margin: 0 -10px;
    .slick-list {
      padding-top: 60px;
    }
  }
  @media (min-width: ${tablet}) {
    .slider {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 -25px;
      gap: 20px;
    }
  }
`;

const CategoryBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 25px;
  background-color: ${Colors.shadowGreen};
  transition: .25s ease-in-out;
`;

const CategoryWrap = styled(Link)`
  width: calc(33.33% - 50px);
  text-decoration: none;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  -ms-scrollbar-highlight-color: transparent;

  &:hover,
  &:focus {
    ${CategoryBg} {
      filter: blur(2px);
    }
    h4:after,
    h4:after {
      opacity: 1;
      transform: scale(1);
    }
  }

  @media (max-width: ${tablet}) {
    margin: 0;
  }
`;

const CategoryBox = styled.div`
  position: relative;
  padding: 15px;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  align-items: center;

  @media (max-width: ${tablet}) {
    width: auto;
    margin: 0 15px 0;
    min-height: auto;
  }
`;

const CategoryImgWrap = styled.div`
  width: 100%;
  height: 300px;
  max-height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  
  div {
    background-color: transparent!important;
  }
  
  img {
    object-fit: contain!important;
  }
`;

const Head4 = styled(H4)`
  ${Typography.underlineLink};
  color: ${Colors.green};
`;

const BottomSubtitle = styled.p`
  max-width: 310px;
  margin: 15px auto 0;
  padding-bottom: 55px;
  font-size: 24px;
  line-height: 31px;
  color: ${Colors.gin};
  text-align: center;
  text-decoration: underline;

  @media (max-width: ${tablet}) {
    margin-top: 75px;
    padding-bottom: 0;
    font-size: 18px;
    line-height: 24px;
  }
`;

export {
  Wrapper,
  Head1,
  Subhead,
  SliderSlick,
  CategoryWrap,
  CategoryBox,
  CategoryBg,
  CategoryImgWrap,
  Head4,
  BottomSubtitle,
}