import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
// Components
import Container from '../../layouts/Container';
import {
  Wrapper,
  Head1,
  Subhead,
  SliderSlick,
  CategoryWrap,
  CategoryBox,
  CategoryBg,
  CategoryImgWrap,
  Head4,
  BottomSubtitle,
} from './styled';

const settings = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  rows: 1,
  responsive: [
    {
      breakpoint: 9999,
      settings: 'unslick'
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 567,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};

const CategoriesCard = ({
  data: {
    title,
    subtitle,
    category,
    bottomSubtitle,
  },
}) => {
  const carouselRef = useRef(null);
  return (
    <Wrapper>
      <Container>
        <Head1 value={title} bottomSubtitle={bottomSubtitle} />
        {subtitle ? <Subhead>{subtitle}</Subhead> : null}
        <SliderSlick
          id="category-slider"
          carouselRef={carouselRef}
          settings={settings}
        >
          {category.map((item) => {
            const { id, name, image, link } = item;
            return (
              <CategoryWrap to={link} key={id}>
                <CategoryBox>
                  <CategoryBg />
                  <CategoryImgWrap>
                    <GatsbyImage
                    image={image?.localFile.childImageSharp.gatsbyImageData}
                    alt={image?.imagename}/>
                  </CategoryImgWrap>
                  <Head4 value={name} />
                </CategoryBox>
              </CategoryWrap>
            );
          })}
        </SliderSlick>
        {bottomSubtitle ? <BottomSubtitle>{bottomSubtitle}</BottomSubtitle> : null}
      </Container>
    </Wrapper>
  );
};

CategoriesCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default CategoriesCard;
