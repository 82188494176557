import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
// Components
import Container from '../../layouts/Container';
import Header from '../../templates/Header';
// Styled
import styled from 'styled-components';
import { tablet } from "../../../assets/styles/Responsive";
import {
  Wrapper,
  Head4,
  Head1,
  ParagraphWrap,
  P,
  Btn,
} from './styled';

//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const MainBanner = ({
  className,
  data: {
    subtitle,
    title,
    text,
    button,
    background,
    backBackground,
  }
}) => {

    const imageData = getImage(background?.localFile?.childImageSharp?.gatsbyImageData)
    const bgimage = convertToBgImage(imageData);

    const bgimageData = getImage(backBackground?.localFile?.childImageSharp?.gatsbyImageData)
    const bgbgimage = convertToBgImage(bgimageData);

  return (
    <BackgroundImage
      Tag="section" 
      className={className}
      alt={title}
      {...bgbgimage}
    >
      <BackgroundImage
        Tag="section"
        alt={title}
        {...bgimage}
      >
        <Wrapper>
          <Container>
            <Header />
            <Head4 value={subtitle} />
            <Head1 value={title} />
            <ParagraphWrap>
              <P>{text}</P>
            </ParagraphWrap>
            <Btn value={button.text} href={button.href} />
          </Container>
        </Wrapper>
      </BackgroundImage>
    </BackgroundImage>
  );
};

MainBanner.propTypes = {
  data: PropTypes.shape({
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    button: PropTypes.shape({}).isRequired,
    background: PropTypes.shape({}).isRequired,
    backBackground: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const Banner = styled(MainBanner)`
  padding: 45px 0 0 50px;
  background-size: cover;
  background-position: center;
  opacity: 1 !important;
  z-index: 99;
  background-color: var(--lightgreen);

  @media (max-width: ${tablet}) {
    padding: 0px;
  }
`;

export default Banner;
