import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
// Styled
import Typography from '../../../assets/styles/Typography';
import { tablet } from "../../../assets/styles/Responsive";
// UI
import H1 from '../../UI/Titles/H1';
import H4 from '../../UI/Titles/H4';
import Button from '../../UI/Button';

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 200px;
  background-size: cover;
  background-position: center;

  @media (max-width: ${tablet}) {
    padding-bottom: 250px;
  }
`;

const Head4 = styled(H4)`
  margin-top: 3.5vh;

  @media (max-width: ${tablet}) {
    margin-top: 20px;
  }
`;

const Head1 = styled(H1)`
  margin-top: 5vh;
  max-width: 815px;

  @media (max-width: ${tablet}) {
    margin-top: 30px;
  }
`;

const ParagraphWrap = styled.div`
  max-width: 720px;
`;

const P = styled(ReactMarkdown)`
  ${Typography.richText};
  margin-top: 3vh;
`;

const Btn = styled(Button)`
  margin-top: 4.5vh;
  padding: 3vh;

  @media (max-width: ${tablet}) {
    margin-top: 40px;
  }
`;

export {
  Wrapper,
  Head4,
  Head1,
  ParagraphWrap,
  P,
  Btn,
}